<template>
  <div class="course-container">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>学习管理</el-breadcrumb-item>
        <el-breadcrumb-item>课程目标</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="minBody">
      <div class="cardList">
        <div class="top">
          <div class="tittle">
            <img src="../../../../assets/1.png" height="20" width="20" style="margin-right: 3px" />
            设置目标
          </div>
          <div style="width: 70%;white-space: nowrap;display:flex;align-items: center;margin-right: 12px;justify-content: flex-end">
            <Button type="primary" @click=";(showAdd = true), (isAdd = true)">目标</Button>
            <Button type="primary" :disabled="showAdd" @click="delTarget" style="margin: 0 10px">删除</Button>
            <Button type="primary" @click="toTargetChart" title="设置目标">
              <!--              <i class="iconfont icon-zhankaihuabu"></i>-->
              <Icon type="md-expand" :size="20" />
            </Button>
          </div>
        </div>
        <div style="height: calc(100% - 55px);overflow: auto;">
          <div v-if="showAdd === true" style="border-bottom: 1px solid #eaedf1;text-align: initial">
            <div style="margin-left: 30px">
              <div style="margin: 15px 0">
                <Input v-model="addTarget" placeholder="请输入目标名称" style="width: 70%" />
              </div>
              <div class="addDescription">
                <Input v-model="addDescription" maxlength="200" show-word-limit type="textarea" placeholder="请输入目标描述" style="width: 70%" />
                <span slot="suffix" style="display: flex; align-items: center; height: 100%;margin-left: 20px">
                  <i class="el-icon-check" @click="addTargetList"></i>
                  <i class="el-icon-close" style="margin-left: 15px" @click="addCancle"></i>
                </span>
              </div>
            </div>
          </div>
          <draggable @end="moveTarget" style="width: 100%">
            <div
              style="width: 100%"
              v-for="target in targetList"
              :key="target.id"
              class="target"
              @click="getTargetList(target)"
              @mouseenter="targetEnter(target)"
              @mouseleave="showIcon = false"
              :style="targetListId === target.id ? 'background-color: #ECF2FC' : ''"
            >
              <div style="width: 100%">
                <div style="width: 100%;display: flex;justify-content: space-between">
                  <CheckboxGroup v-model="targetVal" @on-change="handleCheck" style="margin-left: 20px">
                    <Checkbox :label="target.id">{{ target.name }}</Checkbox>
                  </CheckboxGroup>
                  <div style="white-space: nowrap;" v-if="targetEnterId === target.id && showIcon === true && !showAdd">
                    <svg class="icon" aria-hidden="true" style="height: 14px;width: 14px;margin-right: 12px;cursor: move">
                      <use xlink:href="#icon-lujing724png"></use>
                    </svg>
                    <svg
                      class="icon"
                      @click="updateTarget(target)"
                      aria-hidden="true"
                      style="height: 14px;width: 14px;margin-right: 12px;cursor: pointer"
                    >
                      <use xlink:href="#icon-bianji3"></use>
                    </svg>
                  </div>
                  <span @click="modifyTarget(target)">修改</span>
                </div>
                <div style="width: 100%">
                  <div v-if="showDetail === false">
                    <div class="description">
                      {{ target.description.length > 80 ? target.description.substr(0, 80) + '...' : target.description }}
                    </div>
                    <div class="showDetail" @click="showDetail = true" v-if="target.description.length > 80">
                      展开
                    </div>
                  </div>
                  <div v-if="showDetail === true">
                    <div class="description">{{ target.description }}</div>
                    <div class="showDetail" @click="showDetail = false" v-if="target.description.length > 80">
                      收起
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
      <div class="cardList" style="margin: 0 15px">
        <div class="top">
          <div class="tittle">
            <img src="../../../../assets/2.png" height="20" width="20" style="margin-right: 5px" />
            设置阶段
          </div>
          <div>
            <Button :disabled="targetListId === 0" type="primary" @click=";(showTarget = true), (isStageAdd = true)">新增阶段</Button>
            <Button type="primary" :disabled="showTarget" @click="deleStage" style="margin: 0 12px">删除</Button>
          </div>
        </div>
        <div style="height: calc(100% - 55px);overflow: auto">
          <div v-if="showTarget === true" style="border-bottom: 1px solid #eaedf1;text-align: initial">
            <div style="margin-left: 30px">
              <div style="margin: 15px 0">
                <Input v-model="addStage" placeholder="请输入阶段名称" style="width: 70%" />
              </div>
              <div class="addDescription">
                <Input
                  v-model="addStageDescription"
                  maxlength="200"
                  show-word-limit
                  type="textarea"
                  placeholder="请输入阶段描述"
                  style="width: 70%"
                />
                <span slot="suffix" style="display: flex; align-items: center; height: 100%;margin-left: 20px">
                  <i class="el-icon-check" @click="addStageList"></i>
                  <i class="el-icon-close" style="margin-left: 15px" @click="addStageCancle"></i>
                </span>
              </div>
            </div>
          </div>
          <draggable @end="dragStageEnd">
            <div
              v-for="stage in gradation"
              :key="stage.id"
              @click="getstageList(stage)"
              class="target"
              :style="stageListId === stage.id ? 'background-color: #ECF2FC' : ''"
              @mouseenter="stageEnter(stage)"
              @mouseleave="showStageIcon = false"
            >
              <div style="width: 100%">
                <div style="display: flex;justify-content: space-between;width: 100%">
                  <CheckboxGroup v-model="stageVal" @on-change="handleCheckStage" style="margin-left: 20px">
                    <Checkbox :label="stage.id">{{ stage.name }}</Checkbox>
                  </CheckboxGroup>
                  <div style="white-space: nowrap" v-if="stageEnterId === stage.id && showStageIcon === true && !showTarget">
                    <svg class="icon" aria-hidden="true" style="height: 14px;width: 14px;margin-right: 12px;cursor: move">
                      <use xlink:href="#icon-lujing724png"></use>
                    </svg>
                    <svg
                      class="icon"
                      @click="updateStage(stage)"
                      aria-hidden="true"
                      style="height: 14px;width: 14px;margin-right: 12px;cursor: pointer"
                    >
                      <use xlink:href="#icon-bianji3"></use>
                    </svg>
                  </div>
                  <span @click="modifyTarget(stage)">修改</span>
                </div>
                <div style="width: 100%">
                  <div v-if="showStageDetail === false">
                    <div class="description">
                      {{ stage.description.length > 80 ? stage.description.substr(0, 80) + '...' : stage.description }}
                    </div>
                    <div class="showDetail" @click="showStageDetail = true" v-if="stage.description.length > 80">
                      展开
                    </div>
                  </div>
                  <div v-if="showStageDetail === true">
                    <div class="description">{{ stage.description }}</div>
                    <div class="showDetail" @click="showStageDetail = false" v-if="stage.description.length > 80">
                      收起
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
      <div class="cardList">
        <div class="top">
          <div class="tittle">
            <img src="../../../../assets/3.png" height="20" width="20" style="margin-right: 5px" />
            关联知识点
          </div>
          <div>
            <Button :disabled="stageListId === 0" type="primary" @click="addAcquire">关联知识点</Button>
            <Button :disabled="knowledgeList.length === 0" type="primary" @click="clearKnowledges" style="margin: 0 12px">清空</Button>
          </div>
        </div>
        <div class="knowledgeList">
          <div v-for="knowledge in knowledgeList" :key="knowledge.id">
            <div class="knowledge">
              <div>{{ knowledge.name }}</div>
              <div
                style="display: flex;align-items: center;width: 12px;justify-content: center;margin-left: 5px"
                @mouseenter="showDele(knowledge)"
                @click="handleClose(knowledge)"
                @mouseleave="showDel = false"
              >
                <i class="el-icon-close"></i>
                <!--                <svg-->
                <!--                  v-if="showDel === false || knowledgeId !== knowledge.id"-->
                <!--                  class="icon"-->
                <!--                  aria-hidden="true"-->
                <!--                  style="height: 5px;width: 5px;color: #4579EA;"-->
                <!--                >-->
                <!--                  <use xlink:href="#icon-guanbi1"></use>-->
                <!--                </svg>-->
                <!--                <svg-->
                <!--                  v-if="showDel === true && knowledgeId === knowledge.id"-->
                <!--                  class="icon"-->
                <!--                  aria-hidden="true"-->
                <!--                  style="height: 12px;width: 12px;color: #4579EA;"-->
                <!--                >-->
                <!--                  <use xlink:href="#icon-cha-copy"></use>-->
                <!--                </svg>-->
              </div>
            </div>
          </div>
        </div>
        <Drawer title="知识点" closable v-model="acquire" width="450px">
          <div style="margin-bottom: 15px;">
            <el-input style="width: 300px" placeholder="知识点名称搜索" v-model="knownKeyword" />
          </div>
          <div style="overflow: auto;height: calc(100% - 53px)">
            <el-tree
              node-key="id"
              ref="modalTree"
              :filter-node-method="filterNode"
              @check="handleCheckChange"
              :default-checked-keys="knowledgePointList"
              :data="knowledgePointTree"
              :props="{ label: 'name' }"
              check-strictly
              show-checkbox
              :loading="loading"
            />
          </div>
          <div class="demo-drawer-footer">
            <Button style="margin-right: 8px" @click="cancle">取消</Button>
            <Button type="primary" @click="addknowledge">确定</Button>
          </div>
        </Drawer>
      </div>
    </div>
    <ConfirmDialog ref="deleteDialog" :title="`删除提示`" @on-ok="delStage">
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认删除{{ deleteName }}吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="clearKnowledge" :title="`删除提示`" @on-ok="clearKnowledgePoints">
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认清空知识点吗？
      </div>
    </ConfirmDialog>
    <el-dialog :title="`${modifyType}修改`" :visible.sync="dialogFormVisible">
      <div style="margin-bottom: 20px">描述：<el-input v-model="modifyData.description" style="width: 300px" /></div>
      <div>名称：<el-input v-model="modifyData.name" style="width: 300px" /></div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="modifyDetermine">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import courseApi from '@api/course'
import draggable from 'vuedraggable'
import DeleteModal from '@components/common/DeleteModal'
import knowledge from '@api/knowledge'
import ConfirmDialog from '../../../common/dialog/ConfirmDialog'
import YTIcon from '../../../common/YTIcon'
export default {
  name: 'targetManagement',
  components: { ConfirmDialog, draggable, DeleteModal, YTIcon },
  created() {
    this.getTarget()
  },
  watch: {
    knownKeyword(type) {
      this.$refs.modalTree.filter(type)
    }
  },
  data() {
    return {
      modifyType: '',
      modifyInfo: null,
      modifyData: {
        description: '',
        name: ''
      },
      dialogFormVisible: false,
      isStageAdd: false,
      clearKnowledge: false,
      delStageList: [],
      deleTarget: false,
      delKnge: false,
      showStageIcon: false,
      stageEnterId: 0,
      showIcon: false,
      showDetail: false,
      showStageDetail: false,
      knowledgePointList: [],
      acquire: false,
      addStageDescription: '',
      knownKeyword: '',
      addStage: '',
      showTarget: false,
      loading: false,
      deleteName: '',
      deleteStage: false,
      knowledgeId: 0,
      showDel: false,
      isAdd: false,
      addTarget: '',
      addDescription: '',
      showAdd: false,
      targetList: [],
      targetVal: [],
      stageVal: [],
      delTargetList: [],
      deleteTarget: false,
      targetListId: 0,
      stageListId: 0,
      gradation: [],
      knowledgeList: [],
      knowledgePointId: [],
      knowledgePointTree: [],
      knowledgeIds: [],
      targetEnterId: 0,
      delStages: false,
      sourceGradationId: 0,
      targetGradationId: 0,
      sourceTargetId: 0,
      objectTargetId: 0,
      gradationId: 0
    }
  },
  methods: {
    modifyTarget(data) {
      this.modifyType = data.creatorId ? '目标' : '阶段'
      this.modifyInfo = data
      this.modifyData = JSON.parse(JSON.stringify(data))
      this.dialogFormVisible = true
    },
    modifyDetermine() {
      if (this.modifyInfo.creatorId) {
        courseApi.newTargetModify(this.modifyData, this.modifyInfo.id).then(res => {
          if (res.code === 0) {
            this.$message.success('修改该目标信息成功')
            this.targetListId = this.modifyInfo.id
            this.getTarget()
            this.dialogFormVisible = false
          }
        })
      } else {
        courseApi.newTargetGradationModify(this.modifyData, this.modifyInfo.id).then(res => {
          if (res.code === 0) {
            this.$message.success('修改该阶段信息成功')
            this.getTarget()
            this.dialogFormVisible = false
          }
        })
      }
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    moveTarget(data) {
      this.targetList.map((item, index) => {
        if (data.oldIndex === index) {
          this.sourceTargetId = item.id
        }
        if (data.newIndex === index) {
          this.objectTargetId = item.id
        }
      })
      let payload = {
        sourceTargetId: this.sourceTargetId,
        objectTargetId: this.objectTargetId
      }
      courseApi.targetSwitch(payload).then(res => {
        this.getTarget()
      })
    },
    clearKnowledgePoints() {
      courseApi.clearGradationKnowledge(this.stageListId).then(res => {
        this.$message.success('知识点已清空')
        this.getKnowledge()
        this.clearKnowledge = false
      })
    },
    handleCheckStage(data) {
      this.delStageList = data
      this.delStages = true
    },
    delCancle() {
      this.deleteStage = false
      if (this.delKnge === true) {
        this.delKnge = false
      } else if (this.deleTarget === true) {
        this.deleTarget = false
      }
    },
    dragStageEnd(data) {
      this.gradation.map((item, index) => {
        if (data.oldIndex === index) {
          this.sourceGradationId = item.id
        }
        if (data.newIndex === index) {
          this.targetGradationId = item.id
        }
      })
      this.switchStage()
    },
    switchStage() {
      let payload = {
        sourceGradationId: this.sourceGradationId,
        targetGradationId: this.targetGradationId,
        targetId: this.targetListId
      }
      courseApi.switchGradation(payload).then(res => {
        this.targetGradation()
      })
    },
    stageEnter(data) {
      this.stageEnterId = data.id
      this.showStageIcon = true
    },
    targetEnter(data) {
      this.showIcon = true
      this.targetEnterId = data.id
    },
    cancle() {
      this.acquire = false
    },
    updateStage(data) {
      this.showTarget = true
      this.addStage = data.name
      this.gradationId = data.id
      this.addStageDescription = data.description
    },
    addknowledge() {
      let payload = {
        gradationId: this.stageListId,
        knowledgePointIds: this.knowledgeIds
      }
      courseApi.addGradationKnowledge(payload).then(res => {
        this.$message.success('知识点添加成功')
        this.acquire = false
        this.getKnowledge()
      })
    },
    handleCheckChange(data, checkedKeys) {
      let row = []
      checkedKeys.checkedNodes.map(item => {
        row.push(item.id)
      })
      this.knowledgeIds = row
    },
    addAcquire() {
      this.acquire = true
      this.getKnowledgeTree()
    },
    getKnowledgeTree() {
      this.loading = true
      knowledge
        .getFullKnowledgeTree()
        .then(res => {
          this.knowledgePointTree = res.res.filter(item => {
            if (item.status === false) {
              return item
            }
          })
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    addStageCancle() {
      this.showTarget = false
      this.addStage = ''
      this.addStageDescription = ''
    },
    addStageList() {
      if (this.addStage === '') {
        this.$message.error('请输入阶段名称')
      } else {
        if (this.isStageAdd === true) {
          let payload = {
            name: this.addStage,
            targetId: this.targetListId,
            description: this.addStageDescription
          }
          courseApi.addGradation(payload).then(res => {
            this.$message.success('阶段添加成功')
            this.targetGradation()
            this.addStageCancle()
            this.isStageAdd = false
          })
        } else {
          let payload = {
            description: this.addStageDescription,
            name: this.addStage,
            targetId: this.targetListId,
            gradationId: this.gradationId
          }
          courseApi.targetModifyTarget(payload).then(res => {
            this.$message.success('阶段修改成功')
            this.targetGradation()
            this.addStageCancle()
          })
        }
      }
    },
    delStage() {
      if (this.delKnge === true) {
        let payload = {
          gradationId: this.stageListId,
          knowledgePointIds: this.knowledgePointId
        }
        courseApi.deleKnowledge(payload).then(res => {
          this.$message.success('删除成功')
          this.delKnge = false
          this.deleteStage = false
          this.deleteName = ''
          this.getKnowledge()
        })
      } else if (this.deleTarget === true) {
        courseApi.targetBatchDelete(this.delTargetList).then(res => {
          this.delTargetList = []
          this.deleTarget = false
          this.$message.success('目标删除成功')
          this.deleteStage = false
          this.targetListId = 0
          this.getTarget()
        })
      } else if (this.delStages === true) {
        courseApi.delTargetGradation(this.delStageList, this.targetListId).then(res => {
          this.delStages = false
          this.$message.success('阶段删除成功')
          this.stageListId = 0
          this.targetGradation()
          this.deleteStage = false
        })
      }
    },
    showDele(data) {
      this.showDel = true
      this.knowledgeId = data.id
    },
    handleClose(data) {
      this.delKnge = true
      this.knowledgePointId = [data.id]
      this.deleteName = '知识点:' + data.name
      this.$refs.deleteDialog.open()
    },
    getKnowledge() {
      courseApi.getGradationKnowledge(this.stageListId).then(res => {
        this.knowledgeList = res.res
        let row = []
        res.res.map(item => {
          row.push(item.id)
        })
        this.knowledgePointList = row
      })
    },
    getstageList(data) {
      this.stageListId = data.id
      this.getKnowledge()
    },
    targetGradation() {
      courseApi.targetGradation(this.targetListId).then(res => {
        res.res.map(item => {
          if (item.description === undefined) {
            item.description = ''
          }
        })
        this.gradation = res.res.reverse()
        if (res.res[0] !== undefined && this.stageListId === 0) {
          this.stageListId = this.gradation[0].id
        } else if (res.res.length === 0) {
          this.stageListId = 0
        }
        this.getKnowledge()
      })
    },
    updateTarget(data) {
      this.targetId = data.id
      this.addDescription = data.description
      this.addTarget = data.name
      this.showAdd = true
      this.isAdd = false
    },
    addCancle() {
      this.addDescription = ''
      this.addTarget = ''
      this.showAdd = false
      this.isAdd = false
    },
    addTargetList() {
      if (this.addTarget === '') {
        this.$message.error('请输入目标名称')
      } else {
        if (this.isAdd === true) {
          let payload = {
            description: this.addDescription,
            name: this.addTarget
          }
          courseApi.targetForm(payload).then(res => {
            this.$message.success('目标添加成功')
            this.targetListId = res.res
            this.getTarget()
            this.addCancle()
          })
        } else {
          let payload = {
            targetId: this.targetId,
            description: this.addDescription,
            name: this.addTarget
          }
          courseApi.targetModify(payload).then(res => {
            this.$message.success('目标修改成功')
            this.getTarget()
            this.addCancle()
          })
        }
      }
    },
    getTargetList(data) {
      this.stageListId = 0
      this.targetListId = data.id
      // this.stageListId = 0
      // this.targetListId = []
      this.targetGradation()
    },
    delTarget() {
      if (this.delTargetList.length === 0) {
        this.$message.error('请选择要删除的目标')
      } else {
        this.deleteName = '选中的目标'
        this.$refs.deleteDialog.open()
      }
    },
    deleStage() {
      if (this.delStageList.length === 0) {
        this.$message.error('请选择要删除的阶段')
      } else {
        this.deleteName = '选中的阶段'
        this.$refs.deleteDialog.open()
      }
    },
    clearKnowledges() {
      this.$refs.clearKnowledge.open()
    },
    handleCheck(data) {
      this.deleTarget = true
      this.delTargetList = data
      this.deleteName = '选中的目标'
    },
    getTarget() {
      courseApi.getTarget().then(res => {
        res.res.map(item => {
          if (item.description === undefined) {
            item.description = ''
          }
        })
        this.targetList = res.res
        if (res.res.length !== 0) {
          if (this.targetListId === 0) {
            this.targetListId = res.res[0].id
          }
        } else {
          this.targetListId = 0
        }
        this.targetGradation()
      })
    },
    toTargetChart() {
      this.$router.push('/manage/study/target/chart')
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables';
.minBody {
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 10px;
}
.course-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: @layout-body-background;
}
.cardList {
  height: 100%;
  width: 600px;
  overflow: auto;
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  background-color: #ffffff;
}
.top {
  white-space: nowrap;
  height: 55px;
  width: 100%;
  border-bottom: 1px solid #eaedf1;
  line-height: 55px;
  display: flex;
  justify-content: space-between;
}
.tittle {
  width: 30%;
  font-family: Source Han Sans CN;
  font-size: 16px;
  color: #333333;
  display: flex;
  margin-left: 12px;
  align-items: center;
}
.ivu-btn-primary {
  background-color: #4579ea;
}
.target {
  cursor: pointer;
  text-align: initial;
  min-height: 71px;
  border-bottom: 1px solid #eaedf1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  &:hover {
    background-color: #ecf2fc;
  }
}
.description {
  margin: 8px 40px;
  color: #888888;
}
.addDescription {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  ::v-deep .ivu-input {
    height: 90px;
  }
}
.knowledge {
  cursor: pointer;
  background: #ecf2fc;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 5px;
  min-width: 71px;
  margin: 12px 5px;
  color: #4579ea;
  font-size: 14px;
  border-radius: 3px;
}
.knowledgeList {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 26px 15px;
}
::v-deep .ivu-drawer-header-inner {
  font-size: 16px;
  font-weight: bold;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
/*滚动条的宽度*/
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
::-webkit-scrollbar-track {
  width: 6px;
  background-color: #dcdcdc;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  display: none;
}
/*滚动条的设置*/
::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.showDetail {
  font-size: 12px;
  color: #4579ea;
  float: right;
}
</style>
